import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import { Button } from '../../components/Common/Button'
import List from '../../components/Common/List'

import { toLink } from '../../utils'

import * as s from '../../pages/news.module.scss'

const OnlineConferenceSignalDocs = () => {
  return (
    <>
      <SEO
        title="Опыт ведущих компаний в использовании SIGNAL DOCS"
        description="SIGNAL DOCS - среда общих данных для управления строительством с возможностью просмотра 3D моделей, выдачи замечаний, выстраиванием процессов согласований с возможностью подписания документации с помощью ЭЦП"
      />
      <NewsSectionBlock date="17.07.2024">
        <Typography variant="h1" color="blue">
          {`Онлайн конференция SIGNAL\nКлиентский опыт в использовании SIGNAL DOCS`}
        </Typography>
        <Typography variant="body1">
          Мы продолжаем серию наших онлайн конференции, где пользователи делятся
          своим опытом использования наших продуктов.
        </Typography>
        <Typography variant="body1">
          На этот раз мы пригласили представителей компаний, которые расскажут
          как они внедряли, какие фишки используют и как настроили у себя среду
          общих данных SIGNAL DOCS
        </Typography>
        <Typography variant="body1" mb={24}>
          Конференция пройдет в онлайн формате 24 июля в 11:00 МСК на платформе
          zoom
        </Typography>
        <Button
          size="large"
          className="m-auto"
          onClick={() =>
            toLink(
              'https://us06web.zoom.us/webinar/register/9617210332654/WN_jGJn0zMSRvS27r95_9XblQ#/registration'
            )
          }
        >
          Зарегистрироваться на вебинар
        </Button>
        <Typography variant="body1">Кто выступит?</Typography>
        <List>
          <li>
            Сергей Казанцев, директор по строительству SIGNAL - Обзор
            функционала среды общих данных SIGNAL DOCS
          </li>
          <li>
            Александр Осипов, менеджер проекта информационного моделирования КСМ
            - Применение SIGNAL DOCS в региональном застройщике
          </li>
          <li>
            Мария Бедовая, директор по развитию бизнеса и Елизавета Усиевич,
            BIM-координатор, Development Systems - Как слезть с BIM 360 без боли
            и страданий за 48 часов
          </li>
          <li>
            Сергей Киселев, Руководитель BIM отдела и разработки ПО, ГК Green -
            Среда общих данных на замену BIM 360
          </li>
        </List>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/online-conference-signal-docs/image-1.png"
            alt="Участники конференции"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1" mb={24}>
          Участие в конференции бесплатное и будет полезно IT-специалистам,
          BIM-менеджерам и BIM-координаторам, руководителям строительных
          компаний, специалистам технадзора и стройконтроля, инженерам ПТО и
          проектным организациям
        </Typography>
        <div className="flex flex-col items-center">
          <Button
            size="large"
            onClick={() =>
              toLink(
                'https://us06web.zoom.us/webinar/register/9617210332654/WN_jGJn0zMSRvS27r95_9XblQ#/registration'
              )
            }
          >
            Зарегистрироваться на вебинар
          </Button>
          <Typography variant="body1">До встречи на вебинаре</Typography>
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default OnlineConferenceSignalDocs
